import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {path: '/error_page/:msg',name: 'Error_page',component: () => import('@/views/Auth/ErrorPage.vue')},
  //Auth
  {path: '/login',name: 'Login',component: () => import('@/views/Auth/Login.vue')},
  {path: '/register',name: 'Register',component: () => import('@/views/Auth/Register.vue')},
  {path: '/user',name: 'User',component: () => import('@/views/Auth/Index.vue')},
  {path: '/user_add',name: 'User_add',component: () => import('@/views/Auth/Add.vue')},
  {path: '/user/:id/edit',name: 'User_edit',component: () => import('@/views/Auth/Edit.vue')},
  {path: '/user/:id/permissions',name: 'User_permissions',component: () => import('@/views/Auth/Permissions.vue')},
  //setting
  {path: '/info',name: 'Info',component: () => import('@/views/Setting/Info.vue')},
  {path: '/about',name: 'About ',component: () => import('@/views/Setting/About.vue')},
  {path: '/contact',name: 'Contact ',component: () => import('@/views/Setting/Contact.vue')},
  //category
  {path: '/category',name: 'Category',component: () => import('@/views/Category/Index.vue')},
  {path: '/category_add',name: 'Category_add',component: () => import('@/views/Category/Add.vue')},
  {path: '/category/:id/edit',name: 'Category_edit',component: () => import('@/views/Category/Edit.vue')},
  //posts
  {path: '/post',name: 'Post',component: () => import('@/views/Post/Index.vue')},
  {path: '/post_add',name: 'Post_add',component: () => import('@/views/Post/Add.vue')},
  {path: '/post/:id/edit',name: 'Post_edit',component: () => import('@/views/Post/Edit.vue')},
  //sliders
  {path: '/slider',name: 'Slider',component: () => import('@/views/Slider/Index.vue')},
  {path: '/slider_add',name: 'Slider_add',component: () => import('@/views/Slider/Add.vue')},
  {path: '/slider/:id/edit',name: 'Slider_edit',component: () => import('@/views/Slider/Edit.vue')},
  //competition
  {path: '/competition',name: 'Competition',component: () => import('@/views/Competition/Index.vue')},
  {path: '/competition_add',name: 'Competition_add',component: () => import('@/views/Competition/Add.vue')},
  {path: '/competition/:id/edit',name: 'Competition_edit',component: () => import('@/views/Competition/Edit.vue')},
  //customer service
  {path: '/messages',name: 'Messages',component: () => import('@/views/CustomerService/Messages.vue')},
  {path: '/messages/:id/edit',name: 'Message_edit',component: () => import('@/views/CustomerService/Message.vue')},
  //comment
  {path: '/comments',name: 'Comments',component: () => import('@/views/Comment/Comments.vue')},
  {path: '/comments/:id/edit',name: 'Comment_edit',component: () => import('@/views/Comment/Comment.vue')},
  //competitor
  {path: '/competitors',name: 'Competitors',component: () => import('@/views/Competitor/Competitors.vue')},
  {path: '/competitors/:id/edit',name: 'Competitor_edit',component: () => import('@/views/Competitor/Competitor.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  //let token = store.state.token;
  let token =localStorage.getItem('token');
  let permissions =store.state.permissions;
  console.log("router == " + token)
  if (to.name !== 'Login' && !token) next({ name: 'Login' })
  else next()
  //users
  if (to.name == 'User' && !permissions.some(function(o){return o["name"] == "users view";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'User_add' && !permissions.some(function(o){return o["name"] == "users create";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'User_edit' && !permissions.some(function(o){return o["name"] == "users edit";})) next({ name: 'Error_page', params: { msg: 'unauthorized' } })
  else next()
  if (to.name == 'User_permissions' && !permissions.some(function(o){return o["name"] == "users edit";})) next({ name: 'Error_page' ,params: { msg: 'unauthorized' } })
  else next()
  //settings
  if (to.name == 'Info' && !permissions.some(function(o){return o["name"] == "settings edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'Contact' && !permissions.some(function(o){return o["name"] == "settings edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
   //Messages
   if (to.name == 'Messages' && !permissions.some(function(o){return o["name"] == "messages edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
   else next()
   if (to.name == 'Message_edit' && !permissions.some(function(o){return o["name"] == "messages edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
   else next()
  //category
  if (to.name == 'Category' && !permissions.some(function(o){return o["name"] == "categories view";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'Category_add' && !permissions.some(function(o){return o["name"] == "categories create";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'Category_edit' && !permissions.some(function(o){return o["name"] == "categories edit";})) next({ name: 'Error_page', params: { msg: 'unauthorized' } })
  else next()
  //post
  if (to.name == 'Post' && !permissions.some(function(o){return o["name"] == "posts view";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'Post_add' && !permissions.some(function(o){return o["name"] == "posts create";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'Post_edit' && !permissions.some(function(o){return o["name"] == "posts edit";})) next({ name: 'Error_page', params: { msg: 'unauthorized' } })
  else next()
})

export default router
