import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    name:"",
    permissions:{},
    agreedToPrivacy: true,
    backendUrl:"https://api.pwdgottalent.com/", 
    languages:{ar:'ar'},
    mainLanguage:'ar'
  },
  mutations: {

    initialiseStore(state) {
      localStorage.setItem('agreedToPrivacy', true);

     // if (localStorage.getItem('agreedToPrivacy')) {
         state.agreedToPrivacy = true;
         if(localStorage.getItem('token')){
          state.token = localStorage.getItem('token');
         }
         if(localStorage.getItem('name')){
          state.name = localStorage.getItem('name');
         }
         if(localStorage.getItem('permissions')){
          state.permissions = localStorage.getItem('permissions');
         }
    //  }
      if(state.token == ""){
        return false;
      }
      return true;
    },

    agreePrivacyPolicy(state) {
      localStorage.setItem('agreedToPrivacy', true);
      state.agreedToPrivacy = true;
    },

    saveLogin(state,LoginData){
      state.token =LoginData.token;
      state.name =LoginData.name;
      state.permissions =LoginData.permissions;

      if (localStorage.getItem('agreedToPrivacy')) {
        localStorage.setItem('token', LoginData.token);
        localStorage.setItem('name', LoginData.name);
        localStorage.setItem('permissions', LoginData.permissions);
      }
    },

    Logout(state){
      state.token ="";
      state.name ="";
      state.permissions ={};

      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('permissions');
    }




    

  },
  actions: {
  },
  modules: {
  }
})
