<template>
  <div id="app">
    <NavBar/>
    <Sidebar/>
  <!--     
    <InfoAlert msg="" :visible="false" />
     <DoneAlert msg="" :visible="false" />
     <ErrorAlert msg="" :visible="false" />
     -->
   <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view/>
   
    
  </div>
</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/layout/NavBar.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
/*
import InfoAlert from '@/components/alerts/InfoAlert.vue'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
*/
export default {
 
  components: {
    NavBar,Sidebar,
    
  },
   mounted(){
        this.$i18n.locale = 'en'
    }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
