<template>
    <div>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-nav>
    <b-nav-item v-if="$store.state.token"  v-b-toggle.sidebar-1 class="mr-1" variant="light">  <b-icon icon="three-dots-vertical"></b-icon> </b-nav-item>
    </b-navbar-nav>
    <b-navbar-brand to="/">PWD Got Talent</b-navbar-brand>
    
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
     <!--  <b-navbar-nav>
        <b-nav-item to="/about">About</b-nav-item>
        <b-nav-item href="#" disabled>Disabled</b-nav-item>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-auto">
     <!--     <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form>

        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
-->
        <b-nav-item-dropdown v-if="$store.state.name" right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{$store.state.name}}</em>
          </template>
         
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
export default {
    name: 'NavBar',

    methods:{
      logout(){
        this.$store.commit('Logout');
        this.$router.push('login')
      }
    }
}
</script>