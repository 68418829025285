<template>
 <div>
        
  <b-sidebar id="sidebar-1" :title="$t('Menu')" shadow>
            
  <div class="accordion" role="tablist">
    
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button squared block v-b-toggle.accordion-1 variant="info">{{$t("Catalog") }}</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-list-group flush>
          <b-list-group-item class="p-1" to="/category">{{$t("Categories") }}</b-list-group-item>
        <!--  <b-list-group-item class="p-1" to="/post">{{$t("Posts") }}</b-list-group-item> -->
          <b-list-group-item class="p-1" to="/competition">{{$t("Competition") }}</b-list-group-item>
          <b-list-group-item class="p-1" to="/competitors">{{$t("Competitor") }}</b-list-group-item>
          <b-list-group-item class="p-1" to="/comments">{{$t("Comment") }}</b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button squared block v-b-toggle.accordion-2 variant="info">{{$t("Setting") }}</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-list-group-item class="p-1" to="/user">{{$t("Users") }}</b-list-group-item>
        <b-list-group-item class="p-1" to="/info">{{$t("Information") }}</b-list-group-item>
        <b-list-group-item class="p-1" to="/contact">{{$t("Contact") }}</b-list-group-item>
        <b-list-group-item class="p-1" to="/about">{{$t("About") }}</b-list-group-item>
        <b-list-group-item class="p-1" to="/slider">{{$t("Sliders") }}</b-list-group-item>
        <b-list-group-item class="p-1" to="/messages">{{$t("Messages") }}</b-list-group-item>
      </b-collapse>
    </b-card>

  
  </div>
           
  </b-sidebar>
      
  </div>
</template>
  
<script>
  export default {
      name: 'Sidebar',
    data() {
      return {
        //title: $t('Menu')
      }
    }
  }
</script>
